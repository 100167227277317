<template>
	<!-- Active Users Card -->
	<a-card :bordered="false" class="dashboard-bar-chart">
		<chart-bar :height="220" :loading="isLoading" :mydata="barChartData"></chart-bar>
		<div class="card-title">
			<h6>Последняя информация</h6>
		</div>
		<div class="card-content">
			<p>Сводка за последние 7 дней</p>
		</div>
		<a-row class="card-footer" type="flex" justify="center" align="top">
			<a-col :span="6">
				<h4>{{ barCharCard[0] }}</h4>
				<span>Польз.</span>
			</a-col>
			<a-col :span="6">
				<h4>{{ barCharCard[1] }}</h4>
				<span>Тикетов</span>
			</a-col>
			<a-col :span="6">
				<h4>{{ barCharCard[2] }}</h4>
				<span>Акт. админ.</span>
			</a-col>
			<a-col :span="6">
				<h4>{{ barCharCard[3] }}</h4>
				<span>Ответов</span>
			</a-col>
		</a-row>
	</a-card>
	<!-- Active Users Card -->
</template>

<script>

// Bar chart for "Active Users" card.
import axios from 'axios';
import ChartBar from '../Charts/ChartBar';

export default ({
	components: {
		ChartBar,
	},
	async created() {
		this.isLoading = true;
		try {
			// fill out labels for bar chart
			/* 			let result = [];
						for (let i = 0; i < 7; i++) {
							let date = new Date();
							date.setDate(date.getDate() - i);
							let formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
							result.push(formattedDate);
						}
						this.$data.barChartData.labels = result; */
			// fetch data from remote endpoint with axios
			const response = await axios.get("/api/v1/dashboard");
			this.$data.barChartData.datasets[0].data = response.data.BarChartData;// [1.0, 2.32, 0, 0, 0, 0, 0];
			this.$data.barCharCard = response.data.BarChartCard;
			// toggle is loading to false for child component property
			this.isLoading = false;
		} catch (error) {
			console.error(error);
			this.isLoading = false;
		}
	},
	data() {
		return {
			isLoading: false,
			barCharCard: [],
			// Data for bar chart.
			barChartData: {
				labels: ["01", "02", "03", "04", "05", "06", "07"],
				datasets: [{
					label: "Заявки",
					backgroundColor: '#fff',
					borderWidth: 0,
					borderSkipped: false,
					borderRadius: 6,
					data: null,//[850, 600, 500, 620, 900, 500, 900],
					maxBarThickness: 20,
				},],
			},
		}
	},
})

</script>
